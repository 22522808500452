<template>
    <div>
        <nav-bar></nav-bar>
        <service-support-banner>
            星戟<br> 应用推广
        </service-support-banner>
        <div class="extension-bg">
            <div class="container">
            <div class="extension-header">星戟应用推广</div>
            <div class="extension-button">application</div>
              <div v-for="(item,index) in extension" :key="index">
                  <div  class="extension-title">{{ item.title }}</div>
                  <div  class="extension-description">{{ item.description}}</div>
                  </div>
              </div>
            </div>
            <div style="background: #222328;" class="text-center">
              <div class="container">
                <div  class="Service-title">推广领域</div>
                <div>
                  <img src="../assets/images/about-us/Service.png"  class="Service-img"/>
                </div>
              </div>
            </div>
        <div>
          <map-company></map-company>
        </div>
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import ServiceSupportBanner from '@/components/ServiceSupportBanner'
import { useRouter } from 'vue-router';
import MapCompany from '@/components/map'
  export default {
    name: 'Home',
    components: {
      navBar,
      Footer,
      ServiceSupportBanner,
      MapCompany
    },
  
    setup() { 
        const router = useRouter()
      const useData = reactive({
        extension: [
            {
                title: "保有简单",
                description: "一站式全生命周期服务 ",
            },
            {
                title: "造型简单",
                description: "标准化、模块化的架构和方案",
            },   
            {
                title: "管理简单",
                description: "10’多点本地触控屏，手机",
            },
            {
                title: "高可靠性",
                description: "高可靠电气元器件支持， 为您的边缘数据中心保驾护航  ",
            },
            {
                title: "实施方便",
                description: "工厂预制化安装和测试，品牌保证， 安装简便质量可靠  ",
              }
          ]
      })
      const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
    }
    return {
        ...toRefs(useData),
        goTo
    }
  }
}
  </script>
  <style scoped>
  .extension-bg{
    width: 100%;
    height:1313px ;
    background: url('../assets/images/service-support/extension-bg.png') no-repeat;
    background-size: cover;
  }
  .extension-title{
    font-size: 36px;padding: 42px 0 20px 50px;
    position: relative;
  }
  .extension-title::before{
    content: '';
    width: 33px;
    height: 33px;
    background: url('../assets/images/service-support/extensionLayout.png') no-repeat;
    background-size: cover;
    position: absolute;
    top: 52px;
    left: 0px;
  }
  .extension-header{
    padding:230px 0px 56px ;font-size: 60px;
  }
  .extension-description{
    font-size: 24px;color: #07222F;opacity: 0.6;width: 350px;
  }
  .extension-button{
    width: 240px;height: 60px;
background: #53B578;
border-radius: 30px;color: #FEFEFF;font-size: 17px;line-height: 60px;text-align: center;
  }
  .Service-title{
  color: #FFFFFF;font-size: 40px;padding-top: 90px;
}
.Service-img{
  width:1093px ;display: block;padding:130px 0 ;margin: 0 auto;
}
.product-list{
    width: 583px;height: 560px;
    margin-bottom: 36px;
    background: no-repeat center center;
    background-size: cover;

}
.product-list-title{
    padding-top: 390px;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
}
.product-list-description{
    font-size: 16px;
    text-align: center;
    padding: 12px 78px;
}
.product-list-button{
    text-align: center;
    color: #666666;
    font-size: 14px;
}
.product-content{
    padding: 56px 0px;
}
.product-content li{
    list-style: none;
}
</style>
  